import { ConnectivityStatus } from "@prismadelabs/prismaid";
import { useEffect } from "react";
import { SwipeData } from "../modules/swipe/SwipeData";

export function useDataReset() {
  useEffect(() => {
    SwipeData.update((s) => {
      s.networkStatus = ConnectivityStatus.ok;
      s.progress = 0;
      s.showDot = false;
    });
  }, []);
}
