import React, { memo, useEffect, useRef } from "react";
import anime from "animejs";

import PositionedScaledImage from "../../common/components/PositionedScaledImage";

import text_placeCard from "../img/scan_text-place-card@2x.png";
import text_swipe from "../img/scan_text-swipe@2x.png";
import text_missed from "../img/scan_text-you-missed@2x.png";
import text_tryAgain from "../img/scan_text-try-again@2x.png";
import text_goal from "../img/scan_text-goal@2x.png";
import { usePrevious } from "../../../hooks/usePrevious";

export enum DisplayText {
  PlaceCard,
  Swipe,
  Missed,
  TryAgain,
  Goal,
  None,
}

type Props = {
  currentText: DisplayText;
};

function Display({ currentText }: Props) {
  let animationRef = useRef() as any;
  const prevText = usePrevious(currentText);

  useEffect(() => {
    if (prevText === DisplayText.Goal) {
      return;
    }

    anime.remove(["#text_placeCard", "#text_swipe", "#text_missed", "#text_tryAgain", "#text_goal", ".displayText"]);

    animationRef.current = anime({
      targets: ".displayText",
      opacity: [
        { value: 1, duration: 200 },
        { value: 0, duration: 200 },
        { value: 1, duration: 200 },
        { value: 0, duration: 200 },
        { value: 1, duration: 200 },
      ],
      duration: 600,
      delay: 50,
    });
  }, [currentText]);

  return (
    <div>
      {currentText === DisplayText.PlaceCard && (
        <PositionedScaledImage
          src={text_placeCard}
          id="text_placeCard"
          alt="placeCard"
          horizontalAlign="center"
          verticalAlign="bottom"
          verticalOffset={-830}
          opacity={0}
          className="displayText"
        />
      )}
      {currentText === DisplayText.Swipe && (
        <PositionedScaledImage
          src={text_swipe}
          id="text_swipe"
          alt="swipe"
          horizontalAlign="center"
          verticalAlign="bottom"
          verticalOffset={-830}
          opacity={0}
          className="displayText"
        />
      )}
      {currentText === DisplayText.Missed && (
        <PositionedScaledImage
          src={text_missed}
          id="text_missed"
          alt="missed"
          horizontalAlign="center"
          verticalAlign="bottom"
          verticalOffset={-830}
          opacity={0}
          className="displayText"
        />
      )}
      {currentText === DisplayText.TryAgain && (
        <PositionedScaledImage
          src={text_tryAgain}
          id="text_tryAgain"
          alt="try again"
          horizontalAlign="center"
          verticalAlign="bottom"
          verticalOffset={-830}
          opacity={0}
          className="displayText"
        />
      )}
      {(currentText === DisplayText.Goal || prevText === DisplayText.Goal) && (
        <PositionedScaledImage
          src={text_goal}
          id="text_goal"
          alt="goal"
          horizontalAlign="center"
          verticalAlign="bottom"
          verticalOffset={-845}
          opacity={0}
          className="z-10 displayText"
        />
      )}
    </div>
  );
}

Display.whyDidYouRender = true;
export default memo(Display);
