import React from "react";

type Props = {
  match: any;
};

const PageShell = (Page: any) => {
  return (props: Props) => (
    <div className="absolute top-0 left-0 w-screen min-h-screen">
      <Page {...props} />
    </div>
  );
};

export default PageShell;
