import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import notice_de from "./modules/notice/translations/de.json";
import notice_en from "./modules/notice/translations/en.json";
import touchSensitivity_de from "./modules/touchSensitivity/translations/de.json";
import touchSensitivity_en from "./modules/touchSensitivity/translations/en.json";

const resources = {
  en: {
    notice: notice_en,
    touchSensitivity: touchSensitivity_en,
  },
  de: {
    notice: notice_de,
    touchSensitivity: touchSensitivity_de,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    supportedLngs: ["en", "de"],

    lng: "en",
    fallbackLng: "en",

    fallbackNS: "common",

    debug: true,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "sup", "u", "b"],
    },
  });

export default i18n;
