import React from "react";
import { Redirect } from "react-router-dom";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";
import { AppData } from "../open/AppData";
import TouchComponent from "./TouchComponent";

type Props = {};

function TouchSensitivity(props: Props) {
  useInvocationCheck();
  const needsTouchSensitivity = AppData.useState(
    (s) => s.needsTouchSensitivity
  );

  if (needsTouchSensitivity) {
    return (
      <OrientationLock>
        <TouchComponent />
      </OrientationLock>
    );
  }

  return <Redirect to="/swipe" />;
}

export default TouchSensitivity;
