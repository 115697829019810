import React, { memo } from "react";
import ScaledImage from "../../common/components/ScaledImage";

import frame from "../img/scan_placement@2x.png";
import card from "../img/scan_card@2x.png";
import pulse from "../img/scan_pulse@2x.png";
import { SwipeData } from "../SwipeData";

type Props = {
  scaleFactor: number;
};

function CardField({ scaleFactor }: Props) {
  const showPulse = SwipeData.useState((s) => s.showDot);
  const bottomOffset = "clamp(1rem,6%,4rem)"
  return (
    <div>
      <div className="absolute top-0 left-0 w-screen h-screen">
        {showPulse && (
          <div
            id="pulseContainer"
            className="absolute flex justify-center w-full"
            style={{
                bottom: bottomOffset,
                marginBottom: -65*scaleFactor
            }}
          >
            <ScaledImage src={pulse} id="pulse" alt="pulse" className="self-center animate-smallping" />
          </div>
        )}

        <div
          id="frameContainer"
          className="absolute flex justify-center w-full h-auto"
          style={{
            bottom: bottomOffset
          }}
        >
          <ScaledImage src={frame} id="frame" alt="frame" className="self-center" />
        </div>
        <div
          id="cardContainer"
          className="absolute flex justify-center w-full h-auto"
          style={{
            bottom: bottomOffset,
            opacity: 0.3,
          }}
        >
          <ScaledImage src={card} id="card" alt="card" className="self-center" />
        </div>
      </div>
    </div>
  );
}

CardField.whyDidYouRender = true;
export default memo(CardField);
