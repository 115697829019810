import React, { useState } from "react";
import { useRouter } from "../../hooks/useRouter";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";
import bg from "./img/start_bg-blurry@2x.png";
import soccerLogo from "./img/start_logo-super-soccer@2x.png";
import startButton from "./img/start_button@2x.png";
import prismade from "../../assets/img/logos/prismade.svg";
import "./style.css"
// Please, only visuals here, as this component is also used as background.
// Checks and Google Analytics calls are to be set in /start/page.tsx

function StartComponent() {
  const router = useRouter();

  const [effect, setEffect] = useState(false);
  const clickSound = new Sound(click);

  return (
    <div
      className="flex flex-col items-center justify-between w-screen min-h-screen text-center whitespace-pre-wrap bg-white bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div>
        <img src={soccerLogo} alt="Logo Super Soccer" className="mt-12 h-52" />
      </div>
      <div>
        <button
          onClick={() => {
            clickSound.play();
            setEffect(true);
            setTimeout(() => {
              router.push("/screenSize");
              setEffect(false);
            }, 200);
          }}
        >
          <img
            src={startButton}
            alt="start"
            className={`${effect && "animate-click"} h-52`}
          />
        </button>
      </div>
      <div>
        <a
          href="https://prismade.com/contact/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={prismade} alt="Logo Prismade" className="h-10 mb-8" />
        </a>
      </div>
    </div>
  );
}

export default StartComponent;
