import React, { memo, useEffect, useRef } from "react";
import anime from "animejs";
import ScaledImage from "../../common/components/ScaledImage";

import goalie from "../img/scan_goalkeeper@2x.png";
import pow from "../img/scan_pow@2x.png";
import football from "../img/scan_football-large@2x.png";

export enum AnimationType {
  Base,
  Goal,
  NoGoal,
  None,
}

type Props = {
  scaleFactor: number;
  animation: AnimationType;
};

const swipeTarget = window.innerWidth / 2;

function Goalie({ scaleFactor, animation }: Props) {
  const goalLine = useRef(0 - (window.innerHeight / scaleFactor) * (10 / 14));

  const goalieCenterX = useRef((window.innerWidth - 188 * scaleFactor) / 2); // hardcoded goalkeeper image width 188px
  const powTargetOffset = useRef((238 * scaleFactor) / 2); // hardcoded goalkeeper image width 188px
  const powCenterX = useRef((window.innerWidth - 238 * scaleFactor) / 2); // hardcoded goalkeeper image width 188px
  const footballTargetOffset = useRef((718 * scaleFactor) / 2); // hardcoded goalkeeper image width 718px - large
  const footballCenterX = useRef((window.innerWidth - 718 * scaleFactor) / 2); // hardcoded goalkeeper image width 718px - large

  const baseAnimationRef = useRef() as any;
  const goalAnimationRef = useRef() as any;
  const noGoalAnimationRef = useRef() as any;

  useEffect(() => {
    switch (animation) {
      case AnimationType.Base:
        handleBaseAnimation();
        break;
      case AnimationType.NoGoal:
        handleNoGoal();
        break;
      case AnimationType.Goal:
        handleGoal();
        break;
      case AnimationType.None:
        break;
    }
  }, [animation]);

  const handleBaseAnimation = () => {
    // reset
    anime.set("#goalieContainer", { left: goalieCenterX.current });
    anime.set("#powContainer", { left: powCenterX, opacity: 0 });
    anime.set("#footballContainer", {
      left: footballCenterX,
      bottom: 0 + 50 * scaleFactor,
      scale: 1,
      opacity: 0,
    });

    baseAnimationRef.current = anime({
      targets: "#goalieContainer",
      keyframes: [
        { left: "-=100", duration: 1000 },
        { left: "+=100", duration: 1000 },
        { left: "+=100", duration: 1000 },
        { left: "-=100", duration: 1000 },
      ],
      loop: true,
      easing: "linear",
    });
  };

  const handleNoGoal = () => {
    // stop goalie
    stopGoalie();
    // move ball to X
    anime.set("#footballContainer", { opacity: 1 });
    noGoalAnimationRef.current = anime({
      targets: "#footballContainer",
      left: swipeTarget - footballTargetOffset.current,
      scale: 0.1,
      bottom: (0 - goalLine.current - 240) * scaleFactor,
      easing: "linear",
      duration: 500,
      complete: function () {
        // show pow
        anime.set("#powContainer", {
          left: swipeTarget - powTargetOffset.current,
        });
        anime({ targets: "#powContainer", opacity: 1, duration: 100 });
      },
    });
  };

  const handleGoal = () => {
    // stop goalie
    stopGoalie();
    // move ball to Y
    anime.set("#footballContainer", { opacity: 1 });
    goalAnimationRef.current = anime({
      targets: "#footballContainer",
      left: swipeTarget - footballTargetOffset.current,
      bottom: (0 - goalLine.current - 240) * scaleFactor + 45,
      scale: 0.1,
      easing: "linear",
      duration: 500,
      complete: () => {
        anime({
          targets: "#footballContainer",
          opacity: 0,
          easing: "linear",
          duration: 50,
          delay: 100,
        });
      },
    });
  };

  const stopGoalie = () => {
    anime.remove(["#goalieContainer"]);
  };

  return (
    <div>
      <div className="absolute top-0 left-0 w-screen h-screen">
        <div
          id="goalieContainer"
          className="absolute"
          style={{
            bottom: (0 - goalLine.current) * scaleFactor,
            left: goalieCenterX.current,
          }}
        >
          <ScaledImage src={goalie} id="goalie" alt="goalie" />
        </div>
        <div
          id="powContainer"
          className="absolute"
          style={{
            bottom: (0 - goalLine.current - 10) * scaleFactor,
            left: powCenterX.current,
            opacity: 0,
          }}
        >
          <ScaledImage src={pow} id="pow" alt="pow" />
        </div>
        <div
          id="footballContainer"
          className="absolute"
          style={{
            bottom: (0 - goalLine.current - 240) * scaleFactor,
            left: footballCenterX.current,
            opacity: 0,
          }}
        >
          <ScaledImage src={football} id="football" alt="football" />
        </div>
      </div>
    </div>
  );
}

Goalie.whyDidYouRender = true;
export default memo(Goalie);
