import React, { useEffect, useRef, useState } from "react";
import { useInvocationCheck } from "../../hooks/useInvocationCheck";
import { useDataReset } from "../../hooks/useDataReset";
import { SwipeData } from "../swipe/SwipeData";
import { Redirect } from "react-router-dom";
import PositionedScaledImage from "../common/components/PositionedScaledImage";
import Sound from "../common/sound/Sound";

import click from "../../assets/sounds/click.mp3";

import back from "../../assets/img/scan_back-button@2x.png";

import bg from "./img/start_bg-blurry@2x.png";
import moment_1 from "./img/verified_video-moment-01@2x.png";
import moment_2 from "./img/verified_video-moment-02@2x.png";
import moment_3 from "./img/verified_video-moment-03@2x.png";

import board from "./img/verified_scoreboard@2x.png";
import anime from "animejs";

type Props = {};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

function Verified(props: Props) {
  useInvocationCheck();
  useDataReset();

  const [redirect, setRedirect] = useState("");
  const [titleSrc, setTitleSrc] = useState("");
  const [url, setUrl] = useState("");

  const clickSound = useRef(new Sound(click));

  const scaleFactor = useRef(SwipeData.useState((s) => s.scaleFactor));

  useEffect(() => {
    let moment = getRandomInt(3) + 1;
    console.log("moment", moment);
    switch (moment) {
      case 1:
        setTitleSrc(moment_1);
        setUrl("https://www.youtube.com/embed/gAPxcVioyQk?controls=0");
        break;
      case 2:
        setTitleSrc(moment_2);
        setUrl("https://www.youtube.com/embed/-ccNkksrfls?controls=0");
        break;
      case 3:
        setTitleSrc(moment_3);
        setUrl("https://www.youtube.com/embed/RM_5tJncHww?controls=0");
        break;
    }

    anime.set("#scoreBoard", { translateY: -1500 * scaleFactor.current });

    setTimeout(() => {
      anime.set("#scoreBoard", { opacity: 1 });
      anime({
        targets: "#scoreBoard",
        translateY: 0,
        duration: 1000,
        easing: "easeOutQuad",
      });
    }, 1000);
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <div className="absolute inset-0 w-screen h-screen bg-white bg-center bg-cover" style={{ backgroundImage: `url(${bg})` }}>
      <div id="scoreBoard" style={{ opacity: 0 }}>
        <PositionedScaledImage id="board" alt="scoreBoard" src={board} horizontalAlign="center" verticalAlign="top" verticalOffset={-100} />
        <div
          className="absolute inset-0 w-screen"
          style={{ top: 0 + 185 * scaleFactor.current }}
          // style={{ top: 0 + 285 * scaleFactor.current }}
        >
          <iframe
            width={575 * scaleFactor.current}
            height={1050 * scaleFactor.current}
            src={url}
            // src="https://www.youtube.com/embed/RM_5tJncHww?controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            className="mx-auto"
          ></iframe>
          <PositionedScaledImage
            id="moment_3"
            alt="amazing moments #3"
            src={titleSrc}
            // src={moment_3}
            horizontalAlign="center"
            verticalAlign="top"
          />
        </div>
      </div>
      <div className="absolute left-0 top-2">
        <button
          onClick={() => {
            clickSound.current.play();
            setRedirect("/start");
          }}
          title="back"
        >
          <img src={back} className="m-2 h-11" alt="back" id="backButton" />
        </button>
      </div>
    </div>
  );
}

export default Verified;
