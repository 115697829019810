import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ErrorCode } from "../../enums/ErrorCode";
import { AppData } from "../open/AppData";
import CardOverlay from "../common/layout/CardOverlay";
import StartComponent from "../start/StartComponent";
import Button from "../common/ui/Button";
import ErrorTitle from "../common/components/ErrorTitle";
import ErrorParagraph from "../common/components/ErrorParagraph";
import { useRouter } from "../../hooks/useRouter";
import Sound from "../common/sound/Sound";
import click from "../../assets/sounds/click.mp3";
import OrientationLock from "../common/layout/screenOrientation/OrientationLock";

type Props = {};

type ErrorMessage = {
  title: string;
  body: string;
  button?: ErrorButton;
};

type ErrorButton = {
  title: string;
  action: any;
};

function Notice(props: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    title: "",
    body: "",
  });

  const errorCode = AppData.useState((s) => s.errorCode);

  const clickSound = new Sound(click);

  useEffect(() => {
    const redirectToApp = () => {
      clickSound.play();
      router.push("/tutorial");
    };

    switch (errorCode) {
      case ErrorCode.UnsupportedDevice:
        setErrorMessage({
          title: t("notice:invalidDevice.title"),
          body: t("notice:invalidDevice.body"),
        });
        break;

      case ErrorCode.UnsupportedBrowser:
        setErrorMessage({
          title: t("notice:invalidBrowser.title"),
          body: t("notice:invalidBrowser.body"),
        });
        break;

      case ErrorCode.ScreenTooSmall:
        setErrorMessage({
          title: t("notice:screenTooSmall.title"),
          body: t("notice:screenTooSmall.body"),
          button: {
            title: t("notice:proceedAnyway"),
            action: redirectToApp,
          },
        });
        break;

      case ErrorCode.InvalidInvocation:
        setErrorMessage({
          title: t("notice:invalidLink.title"),
          body: t("notice:invalidLink.body"),
        });
        break;

      default:
        setErrorMessage({
          title: t("notice:error.title"),
          body: t("notice:error.body"),
        });
        break;
    }
  }, [errorCode, t, router]);

  const button = errorMessage.button?.title ? (
    <Button onClick={errorMessage.button?.action} className="w-full mt-6">
      {errorMessage.button.title}
    </Button>
  ) : (
    ""
  );

  if (errorMessage.body) {
    return (
      <OrientationLock>
        <div>
          <StartComponent />
          <CardOverlay>
            <ErrorTitle>{errorMessage!.title}</ErrorTitle>
            <ErrorParagraph>{errorMessage!.body}</ErrorParagraph>
            {button}
          </CardOverlay>
        </div>
      </OrientationLock>
    );
  } else {
    return null;
  }
}

export default Notice;
