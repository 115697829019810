import { Store } from "pullstate";
import { ConnectivityStatus } from "@prismadelabs/prismaid";

type SwipeDataType = {
  scaleFactor: number;
  networkStatus: ConnectivityStatus;
  showDot: boolean;
  progress: number;
};

export const SwipeData = new Store<SwipeDataType>({
  scaleFactor: 0.5,
  networkStatus: ConnectivityStatus.ok,
  progress: 0,
  showDot: false,
});
